<mat-card>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
            <mat-form-field>
                <mat-label>Usuario</mat-label>
                <input matInput placeholder="Usuario" formControlName="username" autocompleteOff>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" placeholder="Contraseña" formControlName="password" autocompleteOff>
            </mat-form-field>
            <div class="button">
                <button type="submit" mat-button>Login</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>